import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const fybm02HeroImgStyle = {
  position: 'relative' as const,
  margin: 'auto',
  borderRadius: '31px',
  maxWidth: '640px',
  display: 'block'
};

export const FYBM02HeroImage = () => (
  <StaticImage
    src="../../../../images/new-homeowners.jpg"
    alt="New homeowners looking back at their house"
    placeholder="blurred"
    quality={100}
    style={fybm02HeroImgStyle}
  />
);

export const landingPageImgStyle = {
  borderRadius: '50%',
  maxWidth: '250px',
  margin: '30px'
};

const TwoAdultsTwoChildrenImage = () => (
  <StaticImage
    src="../../../../images/two-adults-two-children.png"
    alt="two seated people, each holding a young child"
    placeholder="blurred"
    quality={100}
    style={landingPageImgStyle}
  />
);

const TwoPeopleWithHouseImage = () => (
  <StaticImage
    src="../../../../images/two-people-with-house.png"
    alt="smiling couple standing in front of their new home"
    placeholder="blurred"
    quality={100}
    style={landingPageImgStyle}
  />
);

const TwoPeopleInGardenImage = () => (
  <StaticImage
    src="../../../../images/two-people-in-garden.png"
    alt="smiling couple in front of flowering plants"
    placeholder="blurred"
    quality={100}
    style={landingPageImgStyle}
  />
);

export const landingPageImages = {
  'two-adults-two-children.png': <TwoAdultsTwoChildrenImage />,
  'two-people-with-house.png': <TwoPeopleWithHouseImage />,
  'two-people-in-garden.png': <TwoPeopleInGardenImage />
};
